import Vue from "vue";
import moment from "moment";
import _ from "lodash";

// Output: 01/10/22
Vue.filter("format1Date", value =>
  moment.utc(new Date(value)).format("MM/DD/YY")
);

// Output: February 17, 2024
Vue.filter("format2Date", value => {
  // Process: 2024-02-17T04:28:58.000000Z => February 17, 2024
  let timestamp = moment(new Date(value)).format("MMMM D, YYYY");

  // Process: 1708144137 => February 17, 2024
  if (_.isInteger(value)) {
    const epochTime = value;
    const date = new Date(epochTime * 1000);
    const humanTime = date.toLocaleString();

    timestamp = moment(humanTime).format("MMMM D, YYYY");
  }

  return timestamp;
});

// Output: January 10, 2022 | 12:30 pm
Vue.filter("formatDateTime", value =>
  moment(value).format("MMMM D, YYYY | hh:mm a")
);

// Output: hh:mm:ss
Vue.filter("defaultDisplayTime", value =>
  moment(new Date(value)).format("LTS")
);

// Output: $9.99
Vue.filter("currencyToDollarSymbol", value =>
  value
    ? `$ ${parseFloat(value)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
    : "$ 0.00"
);

// Output: 9.99 USD
Vue.filter("currencyToDollarText", value =>
  value
    ? `${parseFloat(value)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} USD`
    : "0.00 USD"
);

// Output: 3 hours ago
Vue.filter("fromNow", value => moment(new Date(value)).fromNow());

// Output: Sanz Moses - SM
Vue.filter("initials", value => {
  const arr = value.split();
  return `${arr[0].charAt(0).toUpperCase()}${arr[arr.length - 1]
    .charAt(0)
    .toUpperCase()}`;
});
